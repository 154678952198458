.verticalSpacing {
    margin-top: 2em;
    margin-bottom: 2em;
}

.verticalSeparation {
    margin-top: 1em;
}

.floatRight {
    float: right
}